import React from 'react';
var img = './img/demmypay_view.png';

function Hero(){
    return(

        <div className="headerBg">

            <div className="container text-center py-5">
                <div class="mt-5 w-100"></div>
                {/* <span className='border border-2 shadow-2 px-3 py-1 rounded shadow-md bg-white'>
                    <span className='border-end border-2 shadow-md rounded fs-6' style={{ padding: '5px' }}>New feature</span>
                    <span className='px-2 hero'>Check out the team dashboard    <i className='bi bi-arrow-right'></i></span>    
                </span> */}
                <h1 className="fw-bold display-3 mt-5">Seamless Airtime & Data <br></br>Vending for everyone</h1>
                <p className="lead mt-3 fs-5">Instant, Reliable and cost-effective solution for Individual,<br></br> Vendors and Businesses</p>
                <a href="https://app.demmypay.com/register" className="btn btn-primary btn-lg mt-3 px-4 py-3">Get Started</a>

                <div className="row mt-5">
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                        <img src={img} className='img-fluid'></img>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Hero