import React from 'react';

var date = new Date();
var year = date.getFullYear();
var dgrUrl = 'https://demmyglobal.com';

function Footer() {
  return (
    <footer className="bg-light py-3 border-top border-1 text-dark">
      <div className='container py-5'>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h5 className='fw-bold'>
              <img src="./img/DemmyPay.jpg" style={{ width: "150px" }}/>
            </h5>
            <div className='text-muted mt-3 mb-5'>
              Simplifying airtime and data vending with seamless transactions, affordable rates, robust APIs, and 24/7 reliable support.
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className='row'>
              <div className='col-lg-4 c0l-md-4 col-sm-12 mb-3'>
                <h6 className='fw-bold'>Products</h6>
                <ul className='list-unstyled footerList'>
                  <li><a href='#feature'>Buy and sell</a></li>
                  <li><a href='#feature'>Megashare</a></li>
                  <li><a href='#feature'>APIs</a></li>
                </ul>
              </div>
              <div className='col-lg-4 c0l-md-4 col-sm-12 mb-3'>
                <h6 className='fw-bold'>Company</h6>
                <ul className='list-unstyled footerList'>
                  <li><a href='https://demmyglobal.com' target='_blank'>About</a></li>
                  <li><a href='#contact'>Contact</a></li>
                  <li><a href='https://blog.demmypay.com' target='_blank'>News</a></li>
                  <li><a href='#faq'>FAQ</a></li>
                </ul>
              </div>
              <div className='col-lg-4 c0l-md-4 col-sm-12 mb-3'>
                <h6 className='fw-bold'>Legal</h6>
                <ul className='list-unstyled footerList'>
                  <li><a href='#'>Terms</a></li>
                  <li><a href='#'>Privacy</a></li>
                  <li><a href='#'>Security</a></li>
                </ul>
              </div>

            </div>
            
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
           
            <div className='row mb-3'>
              <div class='col-2'>
                <a href='https://web.facebook.com/profile.php?id=100064090385183' target='_blank'><i className='bi bi-facebook'></i></a>
              </div>
              <div class='col-2'>
                <a href='https://x.com/demmy_pay' target='_blank'><i className='bi bi-twitter'></i></a>
              </div>
              <div class='col-2'>
                <a href='https://www.instagram.com/demmypay/' target='_blank'><i className='bi bi-instagram'></i></a>
              </div>
              <div class='col-2'>
                <a href='https://wa.me/2349064000028' target='_blank'><i className='bi bi-whatsapp'></i></a>
              </div>
            </div>
            <div className='row mt-4'>
              <a href='https://www.google.com/maps/place/Demmy+Global+Alagbaka/@7.2540352,4.6081484,10z/data=!4m10!1m2!2m1!1sdemmy+global!3m6!1s0x10478f5b38c1c04b:0x5ecb1608e825fcb2!8m2!3d7.2540352!4d5.2178896!15sCgxkZW1teSBnbG9iYWxaDiIMZGVtbXkgZ2xvYmFskgEQY29ycG9yYXRlX29mZmljZZoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSa2FqVlRMVVJuRUFF4AEA-gEECAAQIQ!16s%2Fg%2F11k_2c7cn_?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D' target='_blank'>12, Ado-Owo Road, Akure, Nigeria</a>
              
            </div>
            <div className='row mt-3'>
              <a href="mailto:hello@demmypay.com">hello@demmypay.com</a>
            </div>
            <div className='row mt-3'>
              <a href="tel:+2348032030493">+234 8032030493</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 p-3 border-top border-1 pt-5">
            <p className="text-center">© {year} All rights reserved.</p> 
            <p className="text-center">A product of <a href={dgrUrl} target="_blank">Demmy Global Resources Limited</a></p>    
          </div>
        </div>
      </div>
      
    </footer>
  );
}

export default Footer;
